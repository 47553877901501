<template>
    <v-container>
        <v-row>
            <v-col class="col-12">
                <img src="@/assets/logo/logo.svg" />
                <h1 class="text-center">
                    Τι είναι οι #LittleGreenFarmers
                </h1>
            </v-col>
            <v-col class="col-12">
                <p>
                    Το #LittleGreenFarmers, είναι μία εφαρμογή ειδικά
                    σχεδιασμένη για παιδιά. Περιέχει DIY έργα που παρουσιάζουν
                    βήμα-βήμα καλλιέργειες, κατασκευές, συνταγές μαγειρικής,
                    καθώς επίσης και συμβουλές για μία πιο υγιεινή διατροφή!
                </p>
                <br />
                <p>
                    Μέσα στα έργα τα παιδιά μπορούν να βρουν πληροφορίες για να
                    δημιουργήσουν τον δικό τους λαχανόκηπο, να καλλιεργήσουν
                    φρούτα και λαχανικά με σεβασμό και αγάπη προς το περιβάλλον,
                    καθώς επίσης και να δημιουργήσουν κατασκευές. Επίσης, στην
                    κατηγορία «αισθητήρες», τα παιδιά μπορούν να βρουν οδηγίες
                    για να φτιάξουν αισθητήρες μέτρησης υγρασίας εδάφους, τους
                    οποίους μπορούν να τοποθετήσουν στις καλλιέργειες τους και
                    να λαμβάνουν συμβουλές σχετικά με τον χρόνο που θα πρέπει να
                    ποτίσουν.
                </p>
                <br />
                <p>
                    Τέλος, το #LittleGreenFarmers περιλαμβάνει μια κατηγορία
                    αφιερωμένη στη διατροφή, την οποία επιμελήθηκε η Ελληνική
                    Διατροφολογική Εταιρεία. Σε αυτή την κατηγορία τα παιδιά
                    μπορούν να βρουν νόστιμες και υγιεινές συνταγές μαγειρικής
                    και χρήσιμες συμβουλές για ένα πιο ισορροπημένο
                    διατροφολόγιο
                </p>
                <br />
                <h2>
                    Σε ποιους απευθύνεται;
                </h2>
                <br />
                <p>
                    Το #LittleGreenFarmers απευθύνεται σε σχολεία,
                    περιβαλλοντικές ομάδες, ΚΔΑΠ και γενικά σε κάθε παιδί και
                    ενήλικα! Τα παιδιά με τη συνοδεία ενήλικα μπορούν να
                    δημιουργήσουν λογαριασμό και να μοιραστούν στην εφαρμογή τα
                    δικά τους DIY έργα.
                </p>
                <p class="text-center">
                    Ελάτε να καλλιεργήσουμε, να κατασκευάσουμε και να
                    δημιουργήσουμε παρέα!
                </p>
                <br />
                <h2>Επικοινωνία</h2>
                <br />
                <p>
                    Σε περίπτωση που έχετε οποιαδήποτε απορία, ερώτηση ή θέλετε
                    να μοιραστείτε μαζί μας κάποιο σχόλιο, μπορείτε να
                    επικοινωνήσετε μαζί στο info[@]agroapps.gr.
                </p>
                <br />
                <p>
                    Το έργο #LittleGreenFarmers υλοποιείται από την
                    <a href="https://agroapps.gr/">AgroApps </a> και την
                    <a href="https://www.afs.edu.gr/">
                        Αμερικανική Γεωργική Σχολή
                    </a>
                    στο πλαίσιο της Δράσης Εθνικής Εμβέλειας «ΕΡΕΥΝΩ – ΔΗΜΙΟΥΡΓΩ
                    – ΚΑΙΝΟΤΟΜΩ» και συγχρηματοδοτείται από την Ευρωπαϊκή Ένωση
                    και εθνικούς πόρους μέσω του Ε.Π. Ανταγωνιστικότητα,
                    Επιχειρηματικότητα &amp; Καινοτομία (ΕΠΑνΕΚ).
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "About",
};
</script>
